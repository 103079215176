import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BlogNav from "../../components/blog/nav/nav"

export const data = graphql`
  query($slug: String!) {
    dibPosts(slug: { eq: $slug }) {
      title
      summary
      slug
      publishedAt
      readtime
      content
      featuredImage
      categories {
        title
        slug
      }
      author {
        name
        slug
        photo
      }
    }
  }
`

const PostPage = props => {

  const post = props?.data?.dibPosts

  return (
    <Layout>
      <SEO title={`Blog: ${post.title}`} />
      <div className='content-wrapper'>
        <div className="container-fluid">
          <div className="blog-header">
            <h2>Blog</h2>
            <BlogNav />
          </div>
          <div className="post-page">
            <h1 className="post-page__title">{post.title}</h1>
            <div className="post-page__author">
              {post.author && (
                <>
                  <img alt={post.author.name} src={post.author.photo} />
                  <p>
                    {post.author.name}·&ndash; {post.publishedAt}
                  </p>
                </>
              ) }
            </div>
            <div className='post-page__content' dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default PostPage
